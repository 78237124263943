import React from 'react'
import Dashboard from '../../views/Dashboard'
import logo from '../../../images/logo-wide.svg'

const Welcome: React.FC = () => {
  return (
    <Dashboard activePage="welcome">
      <div className="main__container">
        <div className="main__wrapper">
          <div className="welcome__wrapper">
            <img src={logo} alt="Meetinga" />
            <p className="welcome__p">
              Oferta szkoleniowa Beck Akademii skierowana jest dla wszystkich osób zajmujących się
              na co dzień aspektami prawnymi i podatkowymi w swojej pracy, przede wszystkim do
              praktyków, a także studentów i absolwentów prawa oraz aplikantów: radcowskich,
              adwokackich, notarialnych, komorniczych. W doborze kadry oraz programu wspierają nas
              tak ważne instytucje środowiska prawniczego i podatkowego jak Fundacja Adwokatury
              Polskiej, Krajowa Rada Radców Prawnych, Okręgowa Rada Adwokacka w Warszawie, Krajowa
              Rada Doradców Podatkowych i inne. Beck Akademia stanowi alternatywę dla szkoleń
              organizowanych w ramach Komisji Doskonalenia Zawodowego oraz umożliwia zdobycie
              punktów doskonalenia zawodowego.
              <br />
              <br />
              <a href="https://akademia.beck.pl" target="_blank">
                → akademia.beck.pl
              </a>
              .
              <br />
              <br /> W ramach Beck Akademii Wydawnictwo oferuje także inne formy szkoleń: <br />
              <br />
              <a href="https://testy-prawnicze.pl" target="_blank">
                → testy-prawnicze.pl
              </a>
              <br />
              <a href="https://e-learning.beck.pl" target="_blank">
                → e-learning.beck.pl
              </a>
            </p>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default Welcome
