import React, { FC, useEffect, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { asyncFetchEvents, asyncFetchEventContent } from '../../redux/dashboard/async'
import { selectSlug } from '../../redux/dashboard/selectors'
import { selectUserToken } from '../../redux/user/selectors'
import { setWidth } from '../../redux/dashboard/slice'
import Routes from '../Routes'

const App: FC = () => {
  const dispatch = useDispatch()
  const slug = useSelector(selectSlug)
  const token = useSelector(selectUserToken)

  useLayoutEffect(() => {
    const splittedHref = window.location.href.split('/')
    const slugIndex = splittedHref.findIndex((element) => element === 'event') + 1
    if (slugIndex > 0) {
      dispatch(
        asyncFetchEventContent({
          slug: splittedHref[slugIndex],
          additionalHeaders: token ? { Authorization: `JWT ${token}` } : {},
        }),
      )
    } else if (slug !== undefined) {
      dispatch(
        asyncFetchEventContent({
          slug,
          additionalHeaders: token ? { Authorization: `JWT ${token}` } : {},
        }),
      )
    }
    dispatch(
      asyncFetchEvents({
        additionalHeaders: token ? { Authorization: `JWT ${token}` } : {},
      }),
    )
  }, [slug])

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth
      dispatch(setWidth(newWidth))
    }

    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  return (
    <>
      <Routes />
    </>
  )
}

export default App
