import React, { FC, useCallback, useState } from 'react'
import Modal from 'react-modal'
import { Link, useHistory } from 'react-router-dom'
import { DashboardMessageTypes, Icons, IconsColor, IconsFillType } from '../../utils/enums'
import Icon from '../Icon'
import { isAfter } from 'date-fns'
import { Dropdown, Menu } from 'antd'
// import arrowDown from '../../../images/arrow-down.svg'
import dashboardMessage from '../../components/Message'
import QuestionModal from '../QuestionModal'

import { useDispatch, useSelector } from 'react-redux'
import {
  selectEventConfiguration,
  selectEvents,
  selectMenuItems,
  selectSlug,
} from '../../redux/dashboard/selectors'
import { Events, MenuProps } from '../../redux/dashboard/interfaces'
import { asyncFetchEventContent } from '../../redux/dashboard/async'
import { addEventToFavorites, deleteEventFromFavorites } from '../../redux/user/slice'
import { selectEventsFavorites, selectUser, selectUserToken } from '../../redux/user/selectors'
import { asyncRegisterEvent } from '../../redux/user/async'
import translate from '../../translate'

export interface Props {
  activePage?: string
  className?: string
}

const Sidebar: FC<Props> = ({ activePage = '', className }) => {
  const menuItems = useSelector(selectMenuItems)
  const user = useSelector(selectUser)
  const token = useSelector(selectUserToken)
  const slug = useSelector(selectSlug)
  const event = useSelector(selectEventConfiguration)
  const events = useSelector(selectEvents)
  const eventsFavorites = useSelector(selectEventsFavorites)

  const [activeDropdown, setActiveDropdown] = useState<string>('all')
  const [activeEvent, setActiveEvent] = useState<null | string>(event?.name || null)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [eventCode, setEventCode] = useState<string>('')
  const [eventSlug, setEventSlug] = useState<string>('')

  const history = useHistory()

  const dispatch = useDispatch()

  const changeEvent = (slug: string, name: string) => {
    dispatch(
      asyncFetchEventContent({
        slug: slug,
        additionalHeaders: { Authorization: `JWT ${token}` },
      }),
    )
    setActiveEvent(name)
  }

  const sendRegisterCode = (code: string) => {
    if (eventCode.length === 0) {
      dashboardMessage(DashboardMessageTypes.warning, 'Pole z kodem nie może być puste.')
    } else {
      dispatch(
        asyncRegisterEvent({
          slug: eventSlug,
          additionalHeaders: { Authorization: `JWT ${token}` },
          body: { code: code },
        }),
      )
      setIsOpen(false)
    }
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  const handleFetchAndRegisterEvent = useCallback(
    (slug: string, events: Events) => {
      if (
        events.event_registration_enabled &&
        !events.event_registration_without_code_enabled &&
        !user?.registered_events.some((obj) => obj.name === events.name)
      ) {
        setIsOpen(true)
        setEventSlug(slug)
      } else {
        if (!user?.registered_events.some((obj) => obj.name === events.name)) {
          dispatch(
            asyncRegisterEvent({
              slug: slug,
              additionalHeaders: { Authorization: `JWT ${token}` },
              body: {},
            }),
          )
          changeEvent(slug, events.name)
        } else {
          history.push(`/event/${slug}/agenda`)
          changeEvent(slug, events.name)
        }
      }
    },
    [changeEvent, dispatch, history, user?.registered_events, token],
  )

  Modal.setAppElement('#root')

  const userMenu = (
    <Menu>
      <Menu.Item key="0" onClick={() => setActiveDropdown('all')}>
        <small>{translate('all_events')}</small>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => setActiveDropdown('favorites')}>
        <small>{translate('fav_events')}</small>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setActiveDropdown('archive')}>
        <small>{translate('archive_events')}</small>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <aside className={className ? `sidebar sidebar__${className}` : 'sidebar'}>
        <div className="sidebar__content">
          <div>
            <label>
              <Icon
                name={Icons.meetingaIcon}
                color={IconsColor.white}
                hoverColor={IconsColor.xiaomi}
                type={IconsFillType.fill}
              />
            </label>
            {/* <div className="sidebar__event-buttons">
              // <button onClick={() => history.push('/add-event')}>
              <button onClick={() => window.open('https://appsumo.meetinga.com/', '_blank')}>
                <span>{translate('add_event')}</span>
                <Icon
                  name={Icons.plus}
                  color={IconsColor.white}
                  hoverColor={IconsColor.xiaomi}
                  type={IconsFillType.fill}
                />
              </button>
              <button
                onClick={() => window.open('https://beckakademia.meetinga.com/pl/admin/', '_blank')}
              >
                <span>{translate('manage_events')}</span>
                <Icon
                  name={Icons.rightUp}
                  color={IconsColor.white}
                  hoverColor={IconsColor.xiaomi}
                  type={IconsFillType.fill}
                />
              </button>
            </div> */}
            <section>
              {event && (
                <ul className="sidebar__list">
                  <li className="sidebar__list-buttons">
                    <div className="sidebar__current-event">
                      <div className="sidebar__list-buttons-wrapper">
                        <img src={event.logo_url} alt={event.name} />
                        {eventsFavorites.includes(event.name) ? (
                          <button onClick={() => dispatch(deleteEventFromFavorites(event.name))}>
                            <Icon
                              name={Icons.heartFull}
                              color={IconsColor.tesla}
                              type={IconsFillType.fill}
                            />
                          </button>
                        ) : (
                          <button onClick={() => dispatch(addEventToFavorites(event.name))}>
                            <Icon
                              name={Icons.heartOutlined}
                              color={IconsColor.grey}
                              type={IconsFillType.stroke}
                            />
                          </button>
                        )}
                      </div>
                      <span>{event.name}</span>
                    </div>
                  </li>

                  {menuItems &&
                    menuItems.map((element: MenuProps) => {
                      if (element.type === 'Dashboard') return null
                      if (element.custom_url) {
                        return (
                          <a key={element.id} href={element.custom_url} target="_blank">
                            <li>{element.title || element.type}</li>
                          </a>
                        )
                      } else if (element.type === 'AskQuestion') {
                        return (
                          <li key={element.id}>
                            <QuestionModal type="text" id={element.id} isAgendaItem={false} />
                          </li>
                        )
                      } else if (element.type === 'Notes') {
                        return (
                          <Link key={element.id} to={'/notes'}>
                            <li className={activePage === 'notes' ? 'active' : undefined}>
                              {element.title || element.type}
                            </li>
                          </Link>
                        )
                      } else if (element.article_id || element.articles_category_id) {
                        return (
                          <Link
                            key={element.id}
                            to={
                              element.article_id
                                ? `/event/${slug}/article/${element.article_id}`
                                : `/event/${slug}/articles/${element.articles_category_id}`
                            }
                          >
                            <li
                              className={
                                activePage === `articles/${element.articles_category_id}` ||
                                activePage === `article/${element.article_id}`
                                  ? 'active'
                                  : undefined
                              }
                            >
                              {element.title || element.type}
                            </li>
                          </Link>
                        )
                      } else
                        return (
                          <Link
                            key={element.id}
                            to={`/event/${slug}/${element.type.toLowerCase()}`}
                          >
                            <li
                              className={
                                activePage === element.type.toLowerCase() ? 'active' : undefined
                              }
                            >
                              {element.title || element.type}
                            </li>
                          </Link>
                        )
                    })}

                  {event.networking_enabled && (
                    <Link to={`/event/${slug}/networking`}>
                      <li className={activePage === 'networking' ? 'active' : undefined}>
                        {translate('networking')}
                      </li>
                    </Link>
                  )}
                </ul>
              )}
              {events && (
                <>
                  <Dropdown overlay={userMenu} trigger={['click']}>
                    <button className="sidebar__dropdown">
                      <small>
                        {activeDropdown === 'all'
                          ? translate('all_events')
                          : activeDropdown === 'favorites'
                          ? translate('fav_events')
                          : translate('archive_events')}
                      </small>
                      <Icon
                        name={Icons.downArrow}
                        color={IconsColor.white}
                        hoverColor={IconsColor.white}
                        type={IconsFillType.fill}
                      />
                    </button>
                  </Dropdown>
                  <ul className="sidebar__sponsors">
                    {events.map((element, index) => {
                      if (
                        (activeDropdown === 'favorites' &&
                          eventsFavorites.includes(element.name)) ||
                        (activeDropdown === 'archive' &&
                          element.date_end &&
                          isAfter(new Date(), new Date(element.date_end))) ||
                        (activeDropdown === 'all' &&
                          !(element.date_end && isAfter(new Date(), new Date(element.date_end))))
                      ) {
                        console.log(element, activeDropdown, 'evenet')
                        return (
                          <li
                            key={Math.random()}
                            onClick={() => {
                              handleFetchAndRegisterEvent(element.slug, element)
                            }}
                            className={event?.name === element.name ? 'sponsor-active' : ''}
                          >
                            <div className="sidebar__sponsors-content">
                              <div className="sidebar__sponsors-content-image">
                                <img src={element.logo_url} alt={element.name} />
                              </div>
                              {eventsFavorites.includes(element.name) ? (
                                <button
                                  onClick={() => dispatch(deleteEventFromFavorites(element.name))}
                                >
                                  <Icon
                                    name={Icons.heartFull}
                                    color={IconsColor.tesla}
                                    type={IconsFillType.fill}
                                  />
                                </button>
                              ) : (
                                <button onClick={() => dispatch(addEventToFavorites(element.name))}>
                                  <Icon
                                    name={Icons.heartOutlined}
                                    color={IconsColor.grey}
                                    type={IconsFillType.stroke}
                                  />
                                </button>
                              )}
                            </div>
                            <span>{element.name}</span>
                            <span>{element.date_start}</span>
                          </li>
                        )
                      }
                    })}
                  </ul>
                </>
              )}
            </section>
          </div>

          <div className="sidebar__settings">
            {/* <button>
            <Icon
              name={Icons.settings}
              color={IconsColor.white}
              hoverColor={IconsColor.xiaomi}
              type={IconsFillType.stroke}
            />
            Ustawienia
          </button> */}

            <img className="sidebar__logo" src="images/logo.svg" alt="logo" />
          </div>
        </div>
      </aside>
      {modalIsOpen && (
        <Modal isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)} style={customStyles}>
          <div className="asking-modal">
            <h2>{translate('enter_code')}</h2>
            <input value={eventCode} onChange={(e) => setEventCode(e.target.value)}></input>
            <div>
              <button className="asking-modal__send-btn" onClick={() => setIsOpen(false)}>
                {translate('cancel')}
              </button>
              <button
                className="asking-modal__send-btn"
                onClick={() => sendRegisterCode(eventCode)}
              >
                {translate('confirm')}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default Sidebar
